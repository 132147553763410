import { assign, createMachine, interpret } from 'xstate';
// eslint-disable-next-line camelcase
import { routing_service } from '../routing/routing_machine';

type error_context = {
  error_message: string;
};

export type error_events =
  | {
      type:
        | 'SHOW_CALCULATE_FAILED'
        | 'SHOW_SUBMIT_FAILED'
        | 'SHOW_GENERIC_ERROR'
        | 'SHOW_RUNTIME_ERROR'
        | 'SHOW_PROXY_POS_ERROR'
        | 'SHOW_REFRESH_ERROR'
        | 'SHOW_UNLINKED_ERROR'
        | 'SHOW_PAYMENT_ERROR';
      data: {
        error_message: string;
      };
    }
  | {
      type: 'CLOSE';
    };

// eslint-disable-next-line camelcase
const error_machine = createMachine(
  {
    id: 'error_machine',
    predictableActionArguments: true,
    preserveActionOrder: true,
    context: {
      // eslint-disable-next-line camelcase
      error_message: '',
    },
    initial: 'hidden',
    tsTypes: {} as import('./error_machine.typegen').Typegen0,
    schema: {} as {
      context: error_context;
      events: error_events;
    },
    states: {
      hidden: {
        on: {
          SHOW_CALCULATE_FAILED: {
            target: 'calculate_visible',
            actions: 'set_error',
          },
          SHOW_SUBMIT_FAILED: {
            target: 'submit_visible',
            actions: 'set_error',
          },
          SHOW_GENERIC_ERROR: {
            target: 'generic_visible',
            actions: 'set_error',
          },
          SHOW_RUNTIME_ERROR: {
            target: 'generic_visible',
            actions: 'set_error',
          },
          SHOW_PROXY_POS_ERROR: {
            target: 'proxy_pos_visible',
            actions: 'set_error',
          },
          SHOW_REFRESH_ERROR: {
            target: 'refresh_error_visible',
            actions: 'set_error',
          },
          SHOW_UNLINKED_ERROR: {
            target: 'unlinked_visible',
          },
          SHOW_PAYMENT_ERROR: {
            target: 'payment_error_visible',
            actions: 'set_error',
          },
        },
      },
      // eslint-disable-next-line camelcase
      calculate_visible: {
        entry: ['open_calculate_modal'],
        tags: 'calculate_error',
        on: {
          CLOSE: {
            target: 'hidden',
            actions: ['close_modal'],
          },
        },
      },
      // eslint-disable-next-line camelcase
      submit_visible: {
        entry: ['open_submit_modal'],
        tags: 'submit_error',
        on: {
          CLOSE: {
            target: 'hidden',
            actions: ['close_modal'],
          },
        },
      },
      // eslint-disable-next-line camelcase
      generic_visible: {
        entry: ['open_generic_modal'],
        tags: 'generic_error',
        on: {
          CLOSE: {
            target: 'hidden',
            actions: ['close_modal'],
          },
        },
      },
      // eslint-disable-next-line camelcase
      proxy_pos_visible: {
        entry: ['open_proxy_pos_modal'],
        tags: 'proxy_pos_error',
        on: {
          CLOSE: {
            target: 'hidden',
            actions: ['close_modal'],
          },
        },
      },
      // eslint-disable-next-line camelcase
      refresh_error_visible: {
        entry: ['open_refresh_error_modal'],
        tags: 'refresh_error',
        on: {
          CLOSE: {
            target: 'hidden',
            actions: ['close_modal'],
          },
        },
      },
      // eslint-disable-next-line camelcase
      unlinked_visible: {
        // eslint-disable-next-line camelcase
        entry: ['open_unlinked_modal'],
        tags: 'unlinked_error',
        on: {
          CLOSE: {
            target: 'hidden',
            actions: ['close_modal', 'handle_unlinked_logout'],
          },
        },
      },
      // eslint-disable-next-line camelcase
      payment_error_visible: {
        entry: ['open_payment_error_modal'],
        tags: 'payment_error',
        on: {
          CLOSE: {
            target: 'hidden',
            actions: ['close_modal'],
          },
        },
      },
    },
  },
  {
    actions: {
      // eslint-disable-next-line camelcase
      set_error: assign({
        // eslint-disable-next-line camelcase
        error_message: (ctx, evt) => evt.data.error_message ?? ctx.error_message,
      }),
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      open_calculate_modal: () => routing_service.send('OPEN_CALCULATE_ERROR_MODAL'),
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      open_submit_modal: () => routing_service.send('OPEN_SUBMIT_ERROR_MODAL'),
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      open_generic_modal: () => routing_service.send('OPEN_GENERIC_ERROR_MODAL'),
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      open_proxy_pos_modal: () => routing_service.send('OPEN_PROXY_POS_ERROR_MODAL'),
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      open_refresh_error_modal: () => routing_service.send('OPEN_REFRESH_ERROR_MODAL'),
      // eslint-disable-next-line camelcase
      open_unlinked_modal: () => routing_service.send('OPEN_UNLINKED_ERROR_MODAL'),
      // eslint-disable-next-line camelcase
      close_modal: assign(() => {
        // eslint-disable-next-line camelcase
        routing_service.send('CLOSE_ERROR_MODAL');
        // eslint-disable-next-line camelcase
        return { error_message: '' };
      }),
      // eslint-disable-next-line camelcase
      handle_unlinked_logout: () => {
        // eslint-disable-next-line camelcase
        routing_service.send('LOGGED_OUT');
      },
      // eslint-disable-next-line camelcase
      open_payment_error_modal: () => routing_service.send('OPEN_PAYMENT_ERROR_MODAL'),
    },
  }
);

// eslint-disable-next-line camelcase
export const error_service = interpret(error_machine, { execute: false })
  .onTransition((s) => {
    requestAnimationFrame(() => {
      // eslint-disable-next-line camelcase
      error_service.execute(s);
    });
  })
  .start();

// eslint-disable-next-line camelcase, @typescript-eslint/no-explicit-any
(window as any).error_service = error_service;
