import { KioskStore, type EventLogMessage } from '../../storage/kiosk_store';
import { LogLevel } from '@grubbrr/nextgen-kiosk-client';
import type { PayRequest, PaymentTransaction } from '../payment_processors/types';
import type { MenuOrder, OrderDetails } from '@grubbrr/nextgen-kiosk-client';
import type { KioskOrderItem, KioskUpsellOffer } from '../../models';
import type { ModifierServiceDerivedState } from '../../states/menu/types';
import { Logger } from '../../logger';
import type { IAppInfoService } from '../../../../../tizen-spa/src/lib/services/app_info_service';
export interface IGemEventService {
  auth_kiosk_registered: () => void;
  auth_kiosk_deregistered: () => void;

  admin_sync_forced: () => void;
  admin_kiosk_launched: () => void;
  admin_panel_opened: () => void;
  admin_invalid_pin: (pin_tried: string) => void;

  sync_success: () => void;
  sync_failed: (error: Error) => void;
  sync_item86_success: (
    data: { added: string } | { removed: string } | { replaced: string[] }
  ) => void;
  sync_item86_failed: (error: unknown, ctx: unknown) => void;

  set_session_id: (id: string) => void;
  session_start: (appInfoService: IAppInfoService) => Promise<void>;
  session_ended: (appInfoService: IAppInfoService) => Promise<void>;
  session_websocket_connection_failed: (message: string) => void;
  session_websocket_closed: (message: string) => void;
  session_kiosk_offline: (offline_at: Date, error?: Error) => void;
  session_kiosk_back_online: (
    offline_at: Date,
    online_at: Date,
    duration: number,
    summary: string,
    error?: Error
  ) => void;

  capture_user_behavior_click: (
    view: string,
    eventType: string,
    summary: string,
    itemSessionId: string | undefined,
    element: string,
    elementId: string,
    quantity: string
  ) => void;

  order_type_selected: (order_type_label: string) => void;
  order_checkout_selected: (order_details?: OrderDetails, order?: MenuOrder) => void;
  order_cancelled_selected: (
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[],
    appInfoService?: IAppInfoService
  ) => void;
  order_validate_failed: (
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ) => void;
  order_submit_failed: (
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ) => void;
  order_payment_applied(
    order_details?: OrderDetails & { discount: number },
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ): unknown;
  order_paid: (
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ) => void;
  order_abandoned: (
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ) => void;
  select_category: (category_id?: string, category_name?: string) => void;
  select_language: (language?: string) => Promise<void>;
  select_item: (
    item_id?: string,
    item_name?: string,
    item_description?: string,
    item_price?: number,
    item_cartItemId?: number | string
  ) => void;
  item_read_more: (item_id?: string) => void;
  select_modifier_group: (Modifier?: ModifierServiceDerivedState) => void;
  select_modifier: (
    modifier_id?: string,
    modifier_name?: string,
    modifier_price?: number,
    modifier_menuItemId?: string
  ) => void;
  select_tip: (Tip: number) => void;
  view_tip: () => Promise<void>;
  select_table_tent: (Tent: string) => void;
  enter_name: (Name: string) => void;
  enter_phone: (Phone: string) => void;
  receipt: () => Promise<void>;
  email_receipt: () => Promise<void>;
  email_receipt_failed: (error: unknown) => void;
  qrcode_receipt: () => Promise<void>;
  qrcode_receipt_failed: (error: unknown) => void;
  text_receipt: () => Promise<void>;
  text_receipt_failed: (error: unknown) => void;
  timeOut: () => Promise<void>;
  item_added: (Item?: KioskOrderItem) => void;
  item_removed: (item_id?: string) => void;
  increase_item_quantity: (item_id?: string, item_qty?: number, action?: string) => void;
  decrease_item_quantity: (item_id?: string, item_qty?: number, action?: string) => void;
  view_upsell: (upsell_details: KioskUpsellOffer[], summary: string) => Promise<void>;
  add_upsell_item: (
    item_id?: string,
    item_name?: string,
    item_description?: string,
    item_price?: number,
    item_cartItemId?: number | string
  ) => void;
  view_discount: () => Promise<void>;
  add_discount: (selected_discount: unknown) => Promise<void>;
  payment_start: () => void;
  payment_failed: (
    transaction_id?: string,
    pay_request?: PayRequest,
    pay_response?: unknown
  ) => void;
  payment_invalid: (transaction_id?: string, pay_request?: PayRequest) => void;
  payment_complete: (transaction_id?: string, pay_request?: PayRequest) => void;
  payment_view: () => Promise<void>;
  payment_method_is_not_available: (payment_method_name: string) => void;
  payment_state_machine_is_in_errored_state: (message: string | undefined) => void;
  process_offline_payment: () => void;
  process_offline_transactions_failed: (name: string, message: string | undefined) => void;
  offline_payment_process_started: (name: string) => void;
  offline_payment_process_completed: (name: string, data?: unknown) => void;
  offline_payment_process_failed: (name: string, error: string) => void;

  loyalty_login_failed: (error?: Error, data?: unknown) => void;
  loyalty_check_rewards_failed: (loyalty_user_id?: string, error?: Error) => void;
  loyalty_redeem_failed: (loyalty_user_id?: string, error?: Error, data?: unknown) => void;
  loyalty_open: () => Promise<void>;
  loyalty_rewards_view: () => Promise<void>;
  loyalty_view_lastfive: () => Promise<void>;
  loyalty_add: () => Promise<void>;
  loyalty_close: () => Promise<void>;

  // printer_receipt_printed: () => void;
  // printer_jammed: () => void;
  // printer_out_of_paper: () => void;
  // printer_low_paper: () => void;
  // printer_head_up: () => void;

  start_jaws_success: () => void;
  start_jaws_failed: (error: Error) => void;
  kill_jaws_success: () => void;
  kill_jaws_failed: (error: Error) => void;

  printer_connect: () => void;
  printer_disconnect: () => void;
  printer_test_receipt: () => void;
  printer_customer_receipt_fail: () => void;
  printer_head_up_fail: () => void;
  printer_out_of_paper_fail: () => void;
  printer_low_paper_fail: () => void;
  printer_paper_jammed_fail: () => void;

  amazon_one_event: (event: string) => void;

  verifone_status: (status: string) => void;
  verifone_request: (message: string) => void;
  verifone_response: (message: string) => void;

  agent_request: (url: string, method: string, body: string) => void;
  agent_response: (url: string, method: string, body: string) => void;
  agent_fail: (url: string, method: string, errorMessage: string) => void;

  core_backend_request: (method: string, body: string) => void;
  core_backend_response: (method: string, body: string) => void;
  core_backend_fail: (method: string, errorMessage: string) => void;

  generic_notification: (type: string, payload: unknown) => void;

  loyalty_machine_transitioned_to: (state: unknown) => void;
  order_machine_transitioned_to: (state: unknown) => void;
  payment_machine_transitioned_to: (state: unknown) => void;
  payment_device_machine_transitioned_to: (name: string, state: unknown, data?: unknown) => void;
}

type EventInfo = {
  category: string;
  type: string;
  severity: LogLevel;
  data?: unknown;
  summary?: string;
  sessionId: string | undefined;
};

export const createGemEvent = (info: EventInfo) => {
  const { severity, type, category, data, summary, sessionId } = info;
  const event: EventLogMessage = {
    sessionId,
    eventId: '',
    category,
    type,
    severity,
    data: JSON.stringify(data ?? {}),
    summary: summary ?? '',
    instant: new Date().toISOString(),
  };
  return event;
};

export const eventsForCategoryFactory = (category: string) => {
  return (info: Omit<EventInfo, 'category'>) => {
    const { severity, type, data, summary, sessionId } = info;
    return createGemEvent({
      category,
      type,
      severity,
      data,
      summary,
      sessionId,
    });
  };
};

export const createGemEventForUserBehavior = (
  sessionId: string,
  view: string,
  eventType: string,
  summary: string,
  itemSessionId: string | undefined,
  element: string,
  elementId: string,
  quantity: string
) => {
  const timeStamp = new Date().toISOString();
  /*
   * NOTE: console.log() is for DEBUG and DEV purposes. Please do not delete. Thank you.
   */
  // console.table([{
  //   // timeStamp,
  //   view,
  //   eventType,
  //   // summary,
  //   itemSessionId,
  //   element,
  //   elementId,
  //   // quantity,
  // }]);
  return userBehaviorEventFactory({
    type: eventType,
    severity: LogLevel.Information,
    data: {
      timeStamp,
      view,
      eventType,
      summary,
      itemSessionId,
      element,
      elementId,
      quantity,
    },
    summary,
    sessionId,
  });
};

export const userBehaviorEventFactory = eventsForCategoryFactory('insight');

// eslint-disable-next-line camelcase
const get_gem_service = (): IGemEventService => {
  let sessionId = '';

  // eslint-disable-next-line camelcase
  const store_event = async (event: EventLogMessage) => {
    try {
      Logger.log('GEM', event);
      await KioskStore.eventLog.add(event);
    } catch {
      console.log('failed to store gem event');
    }
  };

  // eslint-disable-next-line camelcase
  const write_event = (category: string) => {
    const eventCreator = eventsForCategoryFactory(category);
    return async (type: string, severity: LogLevel, data?: unknown, summary?: string) => {
      const event = eventCreator({
        type,
        severity,
        data,
        summary,
        sessionId,
      });

      await store_event(event);
    };
  };

  //AUHTORIZATION EVENTS
  // eslint-disable-next-line camelcase
  const auth_events = write_event('Authorization');
  // eslint-disable-next-line camelcase
  const auth_kiosk_registered = () => auth_events('KioskRegistered', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const auth_kiosk_deregistered = () => auth_events('KioskDeregistered', LogLevel.Critical);

  //ADMIN EVENTS
  // eslint-disable-next-line camelcase
  const admin_events = write_event('Admin');
  // eslint-disable-next-line camelcase
  const admin_sync_forced = () => admin_events('SyncForced', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const admin_kiosk_launched = () => admin_events('KioskLaunched', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const admin_panel_opened = () => admin_events('Viewed', LogLevel.Information);
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const admin_invalid_pin = (pin_tried: string) =>
    // eslint-disable-next-line camelcase
    admin_events('InvalidPin', LogLevel.Critical, { pin_tried });

  //SYNC EVENTS
  // eslint-disable-next-line camelcase
  const sync_events = write_event('Sync');
  // eslint-disable-next-line camelcase
  const sync_success = () => sync_events('Config&Menu', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const sync_failed = (error: Error) => sync_events('Config&Menu', LogLevel.Critical, { error });
  // eslint-disable-next-line camelcase
  const sync_item86_success = (
    data: { added: string } | { removed: string } | { replaced: string[] }
  ) => sync_events('Item86', LogLevel.Information, data);
  // eslint-disable-next-line camelcase
  const sync_item86_failed = (error: unknown, ctx: unknown) =>
    sync_events('Item86', LogLevel.Error, { error, context: ctx });

  //SESSION EVENTS
  // eslint-disable-next-line camelcase
  const session_events = write_event('Session');
  // const loyalty_events = write_event('Loyalty');
  // const discount_events = write_event('Discount');
  // eslint-disable-next-line camelcase
  const set_session_id = (id: string) => {
    sessionId = id;
  };

  // USER BEHAVIOR
  // eslint-disable-next-line camelcase
  const capture_user_behavior_click = (
    view: string,
    eventType: string,
    summary: string,
    itemSessionId: string | undefined,
    element: string,
    elementId: string,
    quantity: string
  ) => {
    if (eventType != '') {
      const event = createGemEventForUserBehavior(
        sessionId,
        view,
        eventType,
        summary,
        itemSessionId,
        element,
        elementId,
        quantity
      );

      store_event(event);
    }
  };

  // eslint-disable-next-line camelcase
  const session_ended = async (appInfoService: IAppInfoService) =>
    session_events('Closed', LogLevel.Information, {
      ipAddress: await appInfoService.getIpAddress(),
    });
  // eslint-disable-next-line camelcase
  const session_start = async (appInfoService: IAppInfoService) =>
    session_events(
      'Started',
      LogLevel.Information,
      {
        timeStamp: new Date().toISOString(),
        view: 'TapToOrder',
        eventType: 'SessionStart',
        element: '',
        elementId: '',
        ipAddress: await appInfoService.getIpAddress(),
      },
      'Tap to Order screensaver'
    );

  // eslint-disable-next-line camelcase
  let did_websocket_connection_failed = false;
  // eslint-disable-next-line camelcase
  const session_websocket_connection_failed = (message: string) => {
    // eslint-disable-next-line camelcase
    if (did_websocket_connection_failed) {
      return;
    }
    // eslint-disable-next-line camelcase
    did_websocket_connection_failed = true;
    return session_events('WebsocketConnectionFailed', LogLevel.Error, message);
  };

  // eslint-disable-next-line camelcase
  const session_websocket_closed = (message: string) => {
    return session_events('WebsocketClosed', LogLevel.Information, message);
  };

  // eslint-disable-next-line camelcase
  const session_kiosk_offline = (offline_at: Date, error?: Error) =>
    // eslint-disable-next-line camelcase
    session_events('KioskOffline', LogLevel.Critical, { offline_at, error });

  // eslint-disable-next-line camelcase
  const session_kiosk_back_online = (
    // eslint-disable-next-line camelcase
    offline_at: Date,
    // eslint-disable-next-line camelcase
    online_at: Date,
    duration: number,
    summary: string,
    error?: Error
  ) =>
    session_events(
      'KioskOnline',
      LogLevel.Critical,
      // eslint-disable-next-line camelcase
      // eslint-disable-next-line camelcase
      { offline_at, online_at, duration, error },
      summary
    );

  //ORDER EVENTS
  // eslint-disable-next-line camelcase
  const order_events = write_event('Order');
  // eslint-disable-next-line camelcase
  const service_events = write_event('Service');
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const order_type_selected = (order_type_label: string) => {
    if (!sessionId) return;
    // eslint-disable-next-line camelcase
    service_events('Select', LogLevel.Information, { OrderType: order_type_label });
  };
  // eslint-disable-next-line camelcase
  const checkout_events = write_event('Checkout');
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const order_checkout_selected = (order_details?: OrderDetails, order?: MenuOrder) =>
    // eslint-disable-next-line camelcase
    checkout_events('Viewed', LogLevel.Information, { order_details, order });

  // eslint-disable-next-line camelcase
  const order_cancelled_selected = async (
    // eslint-disable-next-line camelcase
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[],
    appInfoService?: IAppInfoService
  ) =>
    order_events('Cancelled', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      order_details,
      order,
      transactions,
      ipAddress: await appInfoService?.getIpAddress(),
    });

  // eslint-disable-next-line camelcase
  const order_validate_failed = (
    // eslint-disable-next-line camelcase
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
    // eslint-disable-next-line camelcase
  ) => order_events('Create', LogLevel.Warning, { order_details, order, transactions });

  // eslint-disable-next-line camelcase
  const order_submit_failed = (
    // eslint-disable-next-line camelcase
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ) =>
    order_events('Create', LogLevel.Error, {
      // eslint-disable-next-line camelcase
      order_details,
      order,
      transactions,
    });

  // eslint-disable-next-line camelcase
  const order_payment_applied = (
    // eslint-disable-next-line camelcase
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ) =>
    order_events('PaymentApplied', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      order_details,
      order,
      transactions,
    });

  // eslint-disable-next-line camelcase
  const order_paid = (
    // eslint-disable-next-line camelcase
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ) =>
    order_events('PaidInFull', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      order_details,
      order,
      transactions,
    });

  // eslint-disable-next-line camelcase
  const order_abandoned = (
    // eslint-disable-next-line camelcase
    order_details?: OrderDetails,
    order?: MenuOrder,
    transactions?: PaymentTransaction[]
  ) =>
    order_events('Abandoned', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      order_details,
      order,
      transactions,
    });

  // eslint-disable-next-line camelcase
  const cart_events = write_event('Cart');
  // eslint-disable-next-line camelcase
  const item_added = (Item?: KioskOrderItem) =>
    cart_events('Create', LogLevel.Information, {
      Item,
    });

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const item_removed = (item_id?: string) =>
    cart_events('Delete', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      item_id,
    });

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const increase_item_quantity = (item_id?: string, item_qty?: number, action?: string) =>
    cart_events('Change', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      item_id,
      // eslint-disable-next-line camelcase
      item_qty,
      action,
    });

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const decrease_item_quantity = (item_id?: string, item_qty?: number, action?: string) =>
    cart_events('Change', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      item_id,
      // eslint-disable-next-line camelcase
      item_qty,
      action,
    });

  // eslint-disable-next-line camelcase
  const upsell_events = write_event('Upsell');
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const view_upsell = (upsell_details: KioskUpsellOffer[], summary: string) =>
    // eslint-disable-next-line camelcase
    upsell_events('Viewed', LogLevel.Information, { upsell_details }, summary);

  // eslint-disable-next-line camelcase
  const add_upsell_item = (
    // eslint-disable-next-line camelcase
    item_id?: string,
    // eslint-disable-next-line camelcase
    item_name?: string,
    // eslint-disable-next-line camelcase
    item_description?: string,
    // eslint-disable-next-line camelcase
    item_price?: number,
    // eslint-disable-next-line camelcase
    item_cartItemId?: number | string
  ) =>
    upsell_events('Create', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      item_cartItemId,
      // eslint-disable-next-line camelcase
      item_id,
      // eslint-disable-next-line camelcase
      item_name,
      // eslint-disable-next-line camelcase
      item_description,
      // eslint-disable-next-line camelcase
      item_price,
    });

  // eslint-disable-next-line camelcase
  const discount_events = write_event('Discount');
  // eslint-disable-next-line camelcase
  const view_discount = () => discount_events('View', LogLevel.Information);
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const add_discount = (selected_discount: unknown) =>
    discount_events('Create', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      selected_discount,
    });

  // eslint-disable-next-line camelcase
  const selection_events = write_event('Category');
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const select_category = (category_id?: string, category_name?: string) =>
    selection_events('Selected', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      category_id,
      // eslint-disable-next-line camelcase
      category_name,
    });
  // eslint-disable-next-line camelcase
  const select_language = (language?: string) =>
    selection_events('Selected', LogLevel.Error, { language });

  // eslint-disable-next-line camelcase
  const item_events = write_event('Item');
  // eslint-disable-next-line camelcase
  const select_item = (
    // eslint-disable-next-line camelcase
    item_id?: string,
    // eslint-disable-next-line camelcase
    item_name?: string,
    // eslint-disable-next-line camelcase
    item_description?: string,
    // eslint-disable-next-line camelcase
    item_price?: number,
    // eslint-disable-next-line camelcase
    item_cartItemId?: number | string
  ) =>
    item_events('Selected', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      item_cartItemId,
      // eslint-disable-next-line camelcase
      item_id,
      // eslint-disable-next-line camelcase
      item_name,
      // eslint-disable-next-line camelcase
      item_description,
      // eslint-disable-next-line camelcase
      item_price,
    });

  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const item_read_more = (item_id?: string) =>
    item_events('ReadMore', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      item_id,
    });

  // eslint-disable-next-line camelcase
  const modifierGroup_events = write_event('ModifierGroup');
  // eslint-disable-next-line camelcase
  const select_modifier_group = (Modifier?: ModifierServiceDerivedState) =>
    modifierGroup_events('Selected', LogLevel.Information, {
      Modifier,
    });

  // eslint-disable-next-line camelcase
  const modifier_events = write_event('Modifier');
  // eslint-disable-next-line camelcase
  const select_modifier = (
    // eslint-disable-next-line camelcase
    modifier_id?: string,
    // eslint-disable-next-line camelcase
    modifier_name?: string,
    // eslint-disable-next-line camelcase
    modifier_price?: number,
    // eslint-disable-next-line camelcase
    modifier_menuItemId?: string
  ) =>
    modifier_events('Selected', LogLevel.Information, {
      // eslint-disable-next-line camelcase
      modifier_id,
      // eslint-disable-next-line camelcase
      modifier_name,
      // eslint-disable-next-line camelcase
      modifier_price,
      // eslint-disable-next-line camelcase
      modifier_menuItemId,
    });

  // eslint-disable-next-line camelcase
  const tip_events = write_event('Tip');
  // eslint-disable-next-line camelcase
  const select_tip = (Tip?: number) =>
    tip_events('Create', LogLevel.Information, {
      Tip,
    });

  // eslint-disable-next-line camelcase
  const view_tip = () => tip_events('Viewed', LogLevel.Information);

  // eslint-disable-next-line camelcase
  const tent_events = write_event('Tent');
  // eslint-disable-next-line camelcase
  const select_table_tent = (Tent?: string) =>
    tent_events('Create', LogLevel.Information, { Tent });

  // eslint-disable-next-line camelcase
  const name_events = write_event('Name');
  // eslint-disable-next-line camelcase
  const enter_name = (Name?: string) => name_events('Name', LogLevel.Information, { Name });

  // eslint-disable-next-line camelcase
  const phone_events = write_event('Phone');
  // eslint-disable-next-line camelcase
  const enter_phone = (Phone?: string) => phone_events('Phone', LogLevel.Information, { Phone });

  // eslint-disable-next-line camelcase
  const receipt_events = write_event('Receipt');
  const receipt = () => receipt_events('Create', LogLevel.Information);

  // eslint-disable-next-line camelcase
  const email_receipt = () => receipt_events('Email', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const email_receipt_failed = (error: unknown) =>
    receipt_events('Email', LogLevel.Error, { error });
  // eslint-disable-next-line camelcase
  const qrcode_receipt = () => receipt_events('QRCode', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const qrcode_receipt_failed = (error: unknown) =>
    receipt_events('QRCode', LogLevel.Error, { error });
  // eslint-disable-next-line camelcase
  const text_receipt = () => receipt_events('Text', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const text_receipt_failed = (error: unknown) => receipt_events('Text', LogLevel.Error, { error });
  const timeOut = () => order_events('Continued', LogLevel.Information);

  //PAYMENT EVENTS
  // eslint-disable-next-line camelcase
  const payment_events = write_event('Payment');
  // eslint-disable-next-line camelcase
  const payment_start = () => payment_events('Create', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const payment_failed = (
    // eslint-disable-next-line camelcase
    transaction_id?: string,
    // eslint-disable-next-line camelcase
    pay_request?: PayRequest,
    // eslint-disable-next-line camelcase
    pay_response?: unknown
    // eslint-disable-next-line camelcase
    // eslint-disable-next-line camelcase
    // eslint-disable-next-line camelcase
  ) => payment_events('Failed', LogLevel.Critical, { transaction_id, pay_request, pay_response });
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const payment_invalid = (transaction_id?: string, pay_request?: PayRequest) =>
    // eslint-disable-next-line camelcase
    // eslint-disable-next-line camelcase
    payment_events('Invalid', LogLevel.Error, { transaction_id, pay_request });
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const payment_complete = (transaction_id?: string, pay_request?: PayRequest) =>
    // eslint-disable-next-line camelcase
    // eslint-disable-next-line camelcase
    payment_events('Complete', LogLevel.Information, { transaction_id, pay_request });
  // eslint-disable-next-line camelcase
  const payment_view = () => payment_events('Viewed', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const payment_method_is_not_available = (payment_method_name: string) =>
    payment_events('NotAvailable', LogLevel.Error, {
      // eslint-disable-next-line camelcase
      message: `${payment_method_name} is not available.`,
    });
  // eslint-disable-next-line camelcase
  const payment_state_machine_is_in_errored_state = (message: string | undefined) => {
    payment_events('Error', LogLevel.Error, {
      event: 'Payment State Machine moved to ERRORED state.',
      message: message,
    });
  };

  //LOYALTY EVENTS
  // eslint-disable-next-line camelcase
  const loyalty_events = write_event('Loyalty');
  // eslint-disable-next-line camelcase
  const loyalty_login_failed = (error?: Error, data?: unknown) =>
    loyalty_events('Failed', LogLevel.Error, { error, data });
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const loyalty_check_rewards_failed = (loyalty_user_id?: string, error?: Error) =>
    // eslint-disable-next-line camelcase
    loyalty_events('CheckRewardsFailed', LogLevel.Error, { loyalty_user_id, error });
  // eslint-disable-next-line camelcase
  // eslint-disable-next-line camelcase
  const loyalty_redeem_failed = (loyalty_user_id?: string, error?: Error, data?: unknown) =>
    loyalty_events('RedeemRewardsFailed', LogLevel.Error, {
      // eslint-disable-next-line camelcase
      loyalty_user_id,
      error,
      data,
    });
  // eslint-disable-next-line camelcase
  const loyalty_open = () => loyalty_events('Opened', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const loyalty_rewards_view = () => loyalty_events('Viewed', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const loyalty_view_lastfive = () => loyalty_events('Viewed', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const loyalty_add = () => loyalty_events('Create', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const loyalty_close = () => loyalty_events('Closed', LogLevel.Information);
  //PRINTER EVENTS
  // const printer_events = write_event('Printer');
  // const printer_receipt_printed = () =>
  //   printer_events('Receipt Printed', LogLevel.Information);
  // const printer_jammed = () => printer_events('Jammed', LogLevel.Critical);
  // const printer_out_of_paper = () => printer_events('No Paper', LogLevel.Critical);
  // const printer_low_paper = () => printer_events('Low Paper', LogLevel.Critical);
  // const printer_head_up = () => printer_events('Head Up', LogLevel.Critical);

  //ADA EVENTS
  // eslint-disable-next-line camelcase
  const ada_events = write_event('ADA');
  // eslint-disable-next-line camelcase
  const start_jaws_success = () => ada_events('Config&Menu', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const start_jaws_failed = (error: Error) =>
    ada_events('Config&Menu', LogLevel.Critical, { error });
  // eslint-disable-next-line camelcase
  const kill_jaws_success = () => ada_events('Config&Menu', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const kill_jaws_failed = (error: Error) =>
    ada_events('Config&Menu', LogLevel.Critical, { error });

  // eslint-disable-next-line camelcase
  const printer_events = write_event('Printing');
  // eslint-disable-next-line camelcase
  const printer_connect = () => printer_events('PrinterConnect', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const printer_disconnect = () => printer_events('PrinterDisconnect', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const printer_test_receipt = () => printer_events('TestReceiptPrinted', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const printer_customer_receipt_fail = () =>
    printer_events('PrintingCustomerReceiptFailedReasonUnknown', LogLevel.Critical);
  // eslint-disable-next-line camelcase
  const printer_head_up_fail = () =>
    printer_events('PrintingCustomerReceiptFaileddHeadUP', LogLevel.Critical);
  // eslint-disable-next-line camelcase
  const printer_paper_jammed_fail = () =>
    printer_events('PrintingCustomerReceiptFaileddPaperJammed', LogLevel.Critical);
  // eslint-disable-next-line camelcase
  const printer_out_of_paper_fail = () =>
    printer_events('PrintingCustomerReceiptFaileddOutOffPaper', LogLevel.Critical);
  // eslint-disable-next-line camelcase
  const printer_low_paper_fail = () =>
    printer_events('PrintingCustomerReceiptFaileddLowPaper', LogLevel.Critical);

  // eslint-disable-next-line camelcase
  const amazon_one_events = write_event('AmazonOne');
  // eslint-disable-next-line camelcase
  const amazon_one_event = (event: string) =>
    amazon_one_events('Config&Menu', LogLevel.Information, { event });

  // eslint-disable-next-line camelcase
  const verifone_requests = write_event('Verifone');
  // eslint-disable-next-line camelcase
  const verifone_status = (status: string) =>
    verifone_requests('Status', LogLevel.Information, { status });
  // eslint-disable-next-line camelcase
  const verifone_request = (event: unknown) =>
    verifone_requests('PaymentRequest', LogLevel.Information, { event });

  // eslint-disable-next-line camelcase
  const verifone_response = (event: unknown) =>
    verifone_requests('PaymentResponse', LogLevel.Information, { event });

  // eslint-disable-next-line camelcase
  const agent_requests = write_event('Agent');
  // eslint-disable-next-line camelcase
  const agent_request = (url: string, method: string, body: string) =>
    agent_requests('Request', LogLevel.Information, { url, method, body });

  // eslint-disable-next-line camelcase
  const agent_response = (url: string, method: string, body: string) =>
    agent_requests('Response', LogLevel.Information, { url, method, body });

  // eslint-disable-next-line camelcase
  const agent_fail = (url: string, method: string, errorMessage: string) =>
    agent_requests('RequestFailure', LogLevel.Error, { url, method, errorMessage });

  // eslint-disable-next-line camelcase
  const core_backends = write_event('Nextgen Cloud');
  // eslint-disable-next-line camelcase
  const core_backend_request = (method: string, body: string) =>
    core_backends('Request', LogLevel.Information, { method, body });

  // eslint-disable-next-line camelcase
  const core_backend_response = (method: string, body: string) =>
    core_backends('Response', LogLevel.Information, { method, body });

  // eslint-disable-next-line camelcase
  const core_backend_fail = (method: string, errorMessage: string) =>
    core_backends('RequestFailure', LogLevel.Error, { method, errorMessage });

  const notification = write_event('Notification');
  // eslint-disable-next-line camelcase
  const generic_notification = (type: string | null, payload: unknown) =>
    notification(type ?? 'Notification', LogLevel.Information, { payload });

  // PROCESS OFFLINE TRANSACTION
  //OFFLINE EVENTS

  // eslint-disable-next-line camelcase
  const processing_offline_transaction_events = write_event('Offline Process');

  // eslint-disable-next-line camelcase
  const process_offline_payment = () =>
    // eslint-disable-next-line camelcase
    processing_offline_transaction_events('Create', LogLevel.Information);
  // eslint-disable-next-line camelcase
  const offline_payment_process_started = (name: string) =>
    // eslint-disable-next-line camelcase
    processing_offline_transaction_events('Started', LogLevel.Information, { name });
  // eslint-disable-next-line camelcase
  const offline_payment_process_completed = (name: string, data?: unknown) =>
    // eslint-disable-next-line camelcase
    processing_offline_transaction_events('Completed', LogLevel.Information, { name, data });
  // eslint-disable-next-line camelcase
  const process_offline_transactions_failed = (name: string, message: string | undefined) =>
    // eslint-disable-next-line camelcase
    processing_offline_transaction_events('Error', LogLevel.Error, { name, message });
  // eslint-disable-next-line camelcase
  const offline_payment_process_failed = (name: string, error: string) =>
    // eslint-disable-next-line camelcase
    processing_offline_transaction_events('Failed', LogLevel.Error, { name, error });

  // STATE MACHINE / PROCESSES EVENTS
  // eslint-disable-next-line camelcase
  const order_machine_events = write_event('Order Process');

  // eslint-disable-next-line camelcase
  const order_machine_transitioned_to = (state: unknown) =>
    // eslint-disable-next-line camelcase
    order_machine_events('StateChangedTo', LogLevel.Information, { state });

  // eslint-disable-next-line camelcase
  const loyalty_machine_events = write_event('Loyalty Process');

  // eslint-disable-next-line camelcase
  const loyalty_machine_transitioned_to = (state: unknown) =>
    // eslint-disable-next-line camelcase
    loyalty_machine_events('StateChangedTo', LogLevel.Information, { state });

  // eslint-disable-next-line camelcase
  const payment_machine_events = write_event('Payment Process');

  // eslint-disable-next-line camelcase
  const payment_machine_transitioned_to = (state: unknown) =>
    // eslint-disable-next-line camelcase
    payment_machine_events('StateChangedTo', LogLevel.Information, { state });

  // eslint-disable-next-line camelcase
  const payment_device_machine_events = write_event('Payment Device Process');

  // eslint-disable-next-line camelcase
  const payment_device_machine_transitioned_to = (name: string, state: unknown, data?: unknown) =>
    // eslint-disable-next-line camelcase
    payment_device_machine_events('StateChangedTo', LogLevel.Information, { name, state, data });

  return {
    // eslint-disable-next-line camelcase
    auth_kiosk_registered,
    // eslint-disable-next-line camelcase
    auth_kiosk_deregistered,

    // eslint-disable-next-line camelcase
    admin_sync_forced,
    // eslint-disable-next-line camelcase
    admin_kiosk_launched,
    // eslint-disable-next-line camelcase
    admin_panel_opened,
    // eslint-disable-next-line camelcase
    admin_invalid_pin,

    // eslint-disable-next-line camelcase
    sync_success,
    // eslint-disable-next-line camelcase
    sync_failed,
    // eslint-disable-next-line camelcase
    sync_item86_success,
    // eslint-disable-next-line camelcase
    sync_item86_failed,

    // eslint-disable-next-line camelcase
    set_session_id,
    // eslint-disable-next-line camelcase
    session_ended,
    // eslint-disable-next-line camelcase
    session_start,
    // eslint-disable-next-line camelcase
    session_websocket_connection_failed,
    // eslint-disable-next-line camelcase
    session_websocket_closed,
    // eslint-disable-next-line camelcase
    session_kiosk_offline,
    // eslint-disable-next-line camelcase
    session_kiosk_back_online,

    // eslint-disable-next-line camelcase
    capture_user_behavior_click,

    // eslint-disable-next-line camelcase
    order_type_selected,
    // eslint-disable-next-line camelcase
    order_checkout_selected,
    // eslint-disable-next-line camelcase
    order_cancelled_selected,
    // eslint-disable-next-line camelcase
    order_validate_failed,
    // eslint-disable-next-line camelcase
    order_submit_failed,
    // eslint-disable-next-line camelcase
    order_payment_applied,
    // eslint-disable-next-line camelcase
    order_paid,
    // eslint-disable-next-line camelcase
    order_abandoned,

    // eslint-disable-next-line camelcase
    payment_start,
    // eslint-disable-next-line camelcase
    payment_failed,
    // eslint-disable-next-line camelcase
    payment_invalid,
    // eslint-disable-next-line camelcase
    payment_complete,
    // eslint-disable-next-line camelcase
    payment_view,
    // eslint-disable-next-line camelcase
    payment_method_is_not_available,
    // eslint-disable-next-line camelcase
    payment_state_machine_is_in_errored_state,

    // eslint-disable-next-line camelcase
    loyalty_login_failed,
    // eslint-disable-next-line camelcase
    loyalty_check_rewards_failed,
    // eslint-disable-next-line camelcase
    loyalty_redeem_failed,
    // eslint-disable-next-line camelcase
    loyalty_open,
    // eslint-disable-next-line camelcase
    loyalty_rewards_view,
    // eslint-disable-next-line camelcase
    loyalty_view_lastfive,
    // eslint-disable-next-line camelcase
    loyalty_add,
    // eslint-disable-next-line camelcase
    loyalty_close,

    // eslint-disable-next-line camelcase
    select_category,
    // eslint-disable-next-line camelcase
    select_language,
    // eslint-disable-next-line camelcase
    select_item,
    // eslint-disable-next-line camelcase
    item_read_more,
    // eslint-disable-next-line camelcase
    select_modifier_group,
    // eslint-disable-next-line camelcase
    select_modifier,
    // eslint-disable-next-line camelcase
    item_added,
    // eslint-disable-next-line camelcase
    item_removed,
    // eslint-disable-next-line camelcase
    decrease_item_quantity,
    // eslint-disable-next-line camelcase
    increase_item_quantity,
    // eslint-disable-next-line camelcase
    view_upsell,
    // eslint-disable-next-line camelcase
    add_upsell_item,
    // eslint-disable-next-line camelcase
    view_discount,
    // eslint-disable-next-line camelcase
    add_discount,
    // eslint-disable-next-line camelcase
    select_table_tent,
    // eslint-disable-next-line camelcase
    view_tip,
    // eslint-disable-next-line camelcase
    select_tip,
    // eslint-disable-next-line camelcase
    enter_name,
    // eslint-disable-next-line camelcase
    enter_phone,
    receipt,
    // eslint-disable-next-line camelcase
    email_receipt,
    // eslint-disable-next-line camelcase
    email_receipt_failed,
    // eslint-disable-next-line camelcase
    qrcode_receipt,
    // eslint-disable-next-line camelcase
    qrcode_receipt_failed,
    // eslint-disable-next-line camelcase
    text_receipt,
    // eslint-disable-next-line camelcase
    text_receipt_failed,
    timeOut,
    // printer_receipt_printed,
    // printer_jammed,
    // printer_out_of_paper,
    // printer_low_paper,
    // printer_head_up,
    // eslint-disable-next-line camelcase
    start_jaws_success,
    // eslint-disable-next-line camelcase
    start_jaws_failed,
    // eslint-disable-next-line camelcase
    kill_jaws_success,
    // eslint-disable-next-line camelcase
    kill_jaws_failed,
    // eslint-disable-next-line camelcase
    printer_connect,
    // eslint-disable-next-line camelcase
    printer_disconnect,
    // eslint-disable-next-line camelcase
    printer_test_receipt,
    // eslint-disable-next-line camelcase
    printer_customer_receipt_fail,
    // eslint-disable-next-line camelcase
    printer_head_up_fail,
    // eslint-disable-next-line camelcase
    printer_out_of_paper_fail,
    // eslint-disable-next-line camelcase
    printer_low_paper_fail,
    // eslint-disable-next-line camelcase
    printer_paper_jammed_fail,

    // eslint-disable-next-line camelcase
    amazon_one_event,

    // eslint-disable-next-line camelcase
    verifone_status,
    // eslint-disable-next-line camelcase
    verifone_request,
    // eslint-disable-next-line camelcase
    verifone_response,

    // eslint-disable-next-line camelcase
    agent_request,
    // eslint-disable-next-line camelcase
    agent_response,
    // eslint-disable-next-line camelcase
    agent_fail,

    // eslint-disable-next-line camelcase
    core_backend_request,
    // eslint-disable-next-line camelcase
    core_backend_response,
    // eslint-disable-next-line camelcase
    core_backend_fail,

    // eslint-disable-next-line camelcase
    generic_notification,

    // eslint-disable-next-line camelcase
    process_offline_payment,
    // eslint-disable-next-line camelcase
    process_offline_transactions_failed,
    // eslint-disable-next-line camelcase
    offline_payment_process_started,
    // eslint-disable-next-line camelcase
    offline_payment_process_completed,
    // eslint-disable-next-line camelcase
    offline_payment_process_failed,

    // eslint-disable-next-line camelcase
    loyalty_machine_transitioned_to,
    // eslint-disable-next-line camelcase
    order_machine_transitioned_to,
    // eslint-disable-next-line camelcase
    payment_machine_transitioned_to,
    // eslint-disable-next-line camelcase
    payment_device_machine_transitioned_to,
  };
};

let _service: IGemEventService | undefined;
if (!_service) _service = get_gem_service();
// eslint-disable-next-line camelcase
export const gem_service = _service;
