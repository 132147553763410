import type {
  ItemServiceStoreState,
  ModGroupService,
  ModGroupServiceContext,
} from '../states/menu/types';
import { getModifierGroupPizzaType } from '../models';
import type { KioskMenuItem } from '../models';
import { get } from 'svelte/store';
import {
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType,
} from '@grubbrr/nextgen-kiosk-client';
import { isPizzaItem } from '../states/menu/utils';

export const getCompoundIdForCategoryAndLayout = (layoutId: string, categoryId: string) => {
  return `${layoutId}-${categoryId}`;
};

// hack used to get around TS errors where discriminated union's are not properly narrowed
type InvalidOneOf = {
  oneofKind: undefined;
};

export const isValidOneOf = <T>(x: T | InvalidOneOf): x is T => {
  return x && !((x as InvalidOneOf).oneofKind === undefined);
};

const allowedModifierGroups = [
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust,
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce,
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Topping,
];
const modifierGroupForSize =
  // eslint-disable-next-line camelcase
  ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Size;
export type ChildModGroupService = { value: ModGroupService };
export const fetchNestedModifiers = (
  modifierGroups: ModGroupService[] | ChildModGroupService[],
  child = false,
  nestedModifierGroups: ModGroupService[]
) => {
  const modGroup = child
    ? (modifierGroups[0] as ChildModGroupService).value
    : (modifierGroups[0] as ModGroupService);
  const group: ModGroupServiceContext = get(modGroup);
  // fetch its type
  const modifierGroupPizzaType = getModifierGroupPizzaType(modGroup.group);
  // check if it is base, sauce and topping then only push
  if (modifierGroupPizzaType && allowedModifierGroups.includes(modifierGroupPizzaType))
    nestedModifierGroups.push(modGroup);
  if (
    modifierGroupPizzaType ===
    // eslint-disable-next-line camelcase
    ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce
  )
    return;
  const childModifiers = group.display.modifiers;
  if (childModifiers.length > 0) {
    // If modifier is already selected then consider that index
    let index = 0;
    const indexHavingQty = childModifiers.findIndex((mod) => get(mod).display.quantity > 0);
    if (indexHavingQty !== -1) {
      index = indexHavingQty;
    }
    const childModifierGroups = get(childModifiers[index]).display.mod_groups;
    // If modifier group size then fetch all children like toppings, sauces else take first child
    if (modifierGroupPizzaType === modifierGroupForSize) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      // eslint-disable-next-line camelcase
      for (const [_, mod_group] of childModifierGroups.entries()) {
        // eslint-disable-next-line camelcase
        fetchNestedModifiers([mod_group], false, nestedModifierGroups);
      }
    } else {
      childModifierGroups.size > 0 &&
        fetchNestedModifiers(
          Array.from(childModifierGroups, ([name, value]) => ({ name, value })),
          true,
          nestedModifierGroups
        );
    }
  }
  return nestedModifierGroups;
};

export const checkIfCrustHasOnlyOneSelectedModifier = (modifierGroups: ModGroupService[]) => {
  if (modifierGroups.length === 0) return false;

  const modGrp = modifierGroups[0];
  const crustLength = Array.from(get(modGrp).display.modifiers).length;
  return checkIfCrustIsSelected(modifierGroups, true) && crustLength === 1;
};
const checkIfCrustIsSelected = (modifierGroups: ModGroupService[], hasOnlyOneModifier = false) => {
  const modGrp = modifierGroups[0];
  return (
    Array.from(get(modGrp).display.modifiers).filter((_mod) => {
      const modState = get(_mod);
      if (modState.display.quantity > 0 && modState.display.mod_groups.size) {
        const selectedSizeModGroup = Array.from(modState.display.mod_groups, ([name, value]) => ({
          name,
          value,
        }))[0].value;
        return (
          get(selectedSizeModGroup).display.modifiers.filter(
            (modifier) => get(modifier).display.quantity > 0
          ).length > 0 &&
          (!hasOnlyOneModifier ||
            (hasOnlyOneModifier && get(selectedSizeModGroup).display.modifiers.length === 1))
        );
      } else {
        return false;
      }
    }).length > 0
  );
};
export const checkModifiersValidation = (
  modifierGroups: ModGroupService[],
  isAllowModifierSelection: boolean[]
) => {
  isAllowModifierSelection[0] = true;
  const nextAllow = checkIfCrustIsSelected(modifierGroups);
  for (const index in modifierGroups) {
    if (+index === 0) continue;
    isAllowModifierSelection[+index] = nextAllow;
  }
  return isAllowModifierSelection;
};

// eslint-disable-next-line camelcase
export const selectModGroupIndex = (menu_item_service: ItemServiceStoreState, index: number) => {
  // eslint-disable-next-line camelcase
  let activeTopLevelIndex = menu_item_service.modifierGroups[0];
  let activeModGroupIndex = 0;
  try {
    if (index !== 0) {
      // Take first modifier group which is base/crust
      // eslint-disable-next-line camelcase
      const modGrp: ModGroupService = menu_item_service.modifierGroups[0];
      // Fetch selected base/crust modifier
      const selectedCrust = get(modGrp).display.modifiers.find(
        (modifier) => get(modifier).display.quantity > 0
      );
      if (!selectedCrust) return;
      // Fetch size modifier group based on selected base/crust
      const selectedSizeModGroup = Array.from(
        get(selectedCrust).display.mod_groups,
        ([name, value]) => ({ name, value })
      )[0].value;
      if (!selectedSizeModGroup) return;
      // Fetch selected size modifier
      const selectedSize = get(selectedSizeModGroup).display.modifiers.find(
        (modifier) => get(modifier).display.quantity > 0
      );
      if (!selectedSize) return;
      // Fetch modifier group with index based on selected size
      activeTopLevelIndex = Array.from(get(selectedSize).display.mod_groups, ([name, value]) => ({
        name,
        value,
      }))[index - 1].value;
    }
    activeModGroupIndex = index;
  } catch (error) {
    console.log('Error while selecting the modifier group', error);
  }
  return {
    activeTopLevelIndex,
    activeModGroupIndex,
  };
};

export const fetchNextModGroupIndex = (activeTopLevelIndex: ModGroupService) => {
  if (
    activeTopLevelIndex &&
    activeTopLevelIndex.group &&
    activeTopLevelIndex.group.domainSpecificAttributes &&
    isValidOneOf(activeTopLevelIndex.group.domainSpecificAttributes.attributes) &&
    activeTopLevelIndex.group.domainSpecificAttributes.attributes.oneofKind === 'pizzaAttributes'
  ) {
    // If active modifier group is sauce then look for nested modifiers and if qty is > 0  then move to next mod group
    if (
      activeTopLevelIndex.group.domainSpecificAttributes.attributes.pizzaAttributes.type ===
        // eslint-disable-next-line camelcase
        ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce &&
      get(activeTopLevelIndex).display.is_valid
    ) {
      // eslint-disable-next-line camelcase
      return ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Sauce;
    } else if (
      activeTopLevelIndex.group.domainSpecificAttributes.attributes.pizzaAttributes.type ===
      // eslint-disable-next-line camelcase
      ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust
    ) {
      // eslint-disable-next-line camelcase
      return ModifierGroupPizzaSpecificAttributes_ModifierGroupPizzaSpecificType.Crust;
    }
  }
  return -1;
};
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export const isModifierGroupHasSelectedModifier = (
  // eslint-disable-next-line camelcase
  modifier_group_service: ModGroupService,
  index: number
) => {
  if (index === 1) {
    const selectedCrust = get(modifier_group_service).display.modifiers.find(
      (modifier) => get(modifier).display.quantity > 0
    );
    if (!selectedCrust) return false;
    // Fetch size modifier group based on selected base/crust
    const selectedSizeModGroup = Array.from(
      get(selectedCrust).display.mod_groups,
      ([name, value]) => ({ name, value })
    )[0].value;
    if (!selectedSizeModGroup) return false;
    // Fetch selected size modifier
    const selectedSize = get(selectedSizeModGroup).display.modifiers.find(
      (modifier) => get(modifier).display.quantity > 0
    );
    if (!selectedSize) return false;
    return true;
  } else if (index === 2 || index === 3) {
    const modifierGroups = Array.from(
      // eslint-disable-next-line camelcase
      modifier_group_service.modifierServices[0].modifierGroupServices,
      ([name, value]) => ({ name, value })
    );
    const lengthCheck = index === 2 ? modifierGroups.length - 1 : 0;
    if (
      modifierGroups.filter((modGroup) =>
        get(modGroup.value).display.modifiers.find((modifier) => get(modifier).display.quantity > 0)
      ).length > lengthCheck
    ) {
      return true;
    }
  }
  return false;
};
export const processItemForPizza = (item: KioskMenuItem) => {
  if (isPizzaItem(item)) {
    // Fetch the crusts from item
    const crusts = item.modifierGroups[0].modifiers.filter(
      (mod) => !mod.isInvisible && !mod.isActive
    );
    // If only one crust then change its default qty to 1
    if (crusts.length === 1) {
      crusts[0].defaultQuantity = 1;
    }
  }
  return item;
};
